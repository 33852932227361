<template>
  <div class="animated fadeIn">
    <b-card style="top: 10px">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <list-select
              :list="machineData"
              :selected-item="selectedId"
              placeholder="Select Machine ID"
              option-value="machineId"
              option-text="name"
              @select="onSelect"
              required
            />
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t("message.selectDate") }} :
            <br />
            <b-form-select
              v-model="searchData.type"
              :options="$t('message.arrays.dateType')"
            />
          </div>
          <div
            class="col-sm-6 col-md-3"
            v-if="searchData.type === 'selectDate'"
          >
            {{ $t("message.date") }} :
            <br />
            <date-picker
              type="datetime"
              v-model="searchData.datetime"
              lang="en"
              range
              format="yyyy-MM-dd"
            ></date-picker>
          </div>
          <div class="col-sm-6 col-md-2">
            <br />
            <b-btn variant="primary" type="submit">{{
              $t("message.search")
            }}</b-btn>
            <!-- <b-btn variant="success" @click="downloadExcel()">{{
              $t("message.search")
            }}</b-btn> -->
          </div>
        </div>
        <br />
      </b-form>

      <div class="chart-wrapper" v-if="chartData.series">
        <apexchart
          height="250"
          :options="options"
          :series="chartData.series"
        ></apexchart>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="table-responsive" v-if="reportData.row">
            <table class="table-grid">
              <thead style="text-align-last: left">
                <th>{{ $t("message.type") }}</th>
                <th>{{ $t("message.productName") }}</th>
                <th>{{ $t("message.amount") }}</th>
                <th>{{ $t("message.sale") }}</th>
              </thead>
              <tbody>
                <template v-for="(wash, washIndex) in reportData.row.wash">
                  <tr>
                    <td
                      :rowspan="reportData.row.wash.length"
                      v-if="washIndex === 0"
                    >
                      {{ $t("message.wash") }}
                    </td>
                    <td>{{ wash.product.name }}</td>
                    <td style="text-align: right">{{ wash.qty }}</td>
                    <td style="text-align: right">{{ wash.price }}</td>
                  </tr>
                </template>
                <template v-for="(dry, dryIndex) in reportData.row.dry">
                  <tr>
                    <td
                      :rowspan="reportData.row.dry.length"
                      v-if="dryIndex === 0"
                    >
                      {{ $t("message.dry") }}
                    </td>
                    <td>{{ dry.product.name }}</td>
                    <td style="text-align: right">{{ dry.qty }}</td>
                    <td style="text-align: right">{{ dry.price }}</td>
                  </tr>
                </template>
                <template v-for="(das, dasIndex) in reportData.row.das">
                  <tr>
                    <td
                      :rowspan="reportData.row.das.length"
                      v-if="dasIndex === 0"
                    >
                      {{ $t("message.das") }}
                    </td>
                    <td>{{ das.product.name }}</td>
                    <td style="text-align: right">{{ das.qty }}</td>
                    <td style="text-align: right">{{ das.price }}</td>
                  </tr>
                </template>
                <tr>
                  <td colspan="2" style="text-align: right">
                    {{ $t("message.sum") }}
                  </td>
                  <td style="text-align: right">{{ reportData.totalQty }}</td>
                  <td style="text-align: right">
                    {{ reportData.totalAmount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-4">
          <div class="table-responsive" v-if="reportData.totalAmount">
            <table class="table-grid">
              <thead style="text-align-last: left">
                <th>{{ $t("message.sum") }}</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t("message.sale") }}</td>
                  <td style="text-align-last: right">
                    {{ reportData.totalAmount }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("message.totalWash") }}</td>
                  <td style="text-align-last: right">
                    {{ reportData.totalWashAmount }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("message.totalDry") }}</td>
                  <td style="text-align-last: right">
                    {{ reportData.totalDryAmount }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("message.totalDAS") }}</td>
                  <td style="text-align-last: right">
                    {{ reportData.totalDASAmount }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import webServices from "../../script";
import { ListSelect } from "../../custom_modules/search-select";
import DatePicker from "../../custom_modules/vue2-datepicker";
import moment from "moment";
export default {
  name: "Reports",
  components: {
    ListSelect,
    DatePicker,
  },
  data() {
    return {
      chartData: {
        options: {
          xaxis: {
            categories: [],
          },
          colors: [],
          chart: { id: "sale-charts" },
        },
        series: [],
      },
      params: {
        from: "",
        to: "",
        machineId: 7,
      },
      options: {},
      machineData: [],
      selectedId: {},
      searchData: {},
      type: "",
      reportData: {},
    };
  },
  methods: {
    downloadExcel() {
      webServices.downloadExcel().then((response) => {
        const now = new Date()
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `hardware_status_report_${now.getDate()}${now.getMonth()}${now.getFullYear()}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getMachine() {
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.machineData = res.data;
          // console.log(this.machineData)
        })
        .catch((err) => {
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    async searchFn() {
      this.chartData.series = [];
      if (this.searchData.type === "selectDate") {
        this.searchData.from = moment(this.searchData.datetime[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.searchData.to = moment(this.searchData.datetime[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        this.searchData.from = "";
        this.searchData.to = "";
      }
      await this.getSaleReport();
      await this.getChartsReport();
    },
    getChartsReport() {
      this.$Progress.start();
      webServices
        .getChartSaleReport(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          if (res.data.success) {
            if (this.searchData.type !== "today") {
              res.data.charts.datasets.forEach((val) => {
                if (val.label !== "totalAmount") {
                  this.chartData.series.push({
                    type: "bar",
                    name:
                      val.label === "totalWashAmount"
                        ? "Wash"
                        : val.label === "totalDryAmount"
                        ? "Dry"
                        : val.label === "totalDASAmount"
                        ? "Detergent & Softener"
                        : null,
                    data: val.data,
                  });
                  this.chartData.options.colors.push(val.backgroundColor);
                } else {
                  this.chartData.series.push({
                    type: "line",
                    name: "Total",
                    data: val.data,
                  });
                  this.chartData.options.colors.push(val.backgroundColor);
                }
              });

              const label = JSON.parse(JSON.stringify(this.chartData.options));
              this.options = label;
              this.options.xaxis.categories = res.data.charts.labels;
            }
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
                ? res.data.errorText
                : res.data.errors,
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText),
            });
          }
        })

        .catch((err) => {
          this.$Progress.fail();
          if (err.response) {
            if (err.response.status === 401) {
              webServices.tokenExpire();
            }
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @Get charts");
          console.log(err);
        });
    },
    getSaleReport() {
      this.$Progress.start();
      webServices
        .getSaleReport(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          this.reportData = res.data;
          if (this.searchData.type === "today") {
            this.chartData.series.push(
              {
                type: "bar",
                name: "Wash",
                data: [this.reportData.totalWashAmount],
              },
              {
                type: "bar",
                name: "Dry",
                data: [this.reportData.totalDryAmount],
              },
              {
                type: "bar",
                name: "Detergent & Softener",
                data: [this.reportData.totalDASAmount],
              },
              {
                type: "line",
                name: "Total",
                data: [this.reportData.totalAmount],
              }
            );
            this.chartData.options.colors.push(
              "#0066FF",
              "#FF6600",
              "#FF33CC",
              "#33CC99"
            );
            const label = JSON.parse(JSON.stringify(this.chartData.options));
            this.options = label;
            this.options.xaxis.categories = [
              `${moment().format("YYYY-MM-DD")}`,
            ];
          }
        })
        .catch((err) => {
          console.log("error @Get charts");
          console.log(err);
          this.$Progress.fail();
          if (err.response) {
            if (err.response.status === 401) {
              webServices.tokenExpire();
            }
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
        });
    },
    onSelect(item) {
      this.selectedId = item;
      this.searchData.machineId = item.machineId;
    },
  },
  mounted() {
    // this.getChartsReport();
    this.getMachine();
  },
};
</script>